import Core, { Plugin } from '@tencent/aegis-core';

let plugin = new Plugin({ name: 'spa' });

if (SPA) {
  plugin = new Plugin({
    name: 'spa',
    init() {
      history.pushState = this.wr('pushState') || history.pushState;
      history.replaceState = this.wr('replaceState') || history.replaceState;
      this.onPageChange();
    },
    onPageChange() {
      // hash
      window.addEventListener('hashchange', (e) => {
        this.sendPv();
      });

      // history
      window.addEventListener('replaceState', () => {
        this.sendPv();
      });
      window.addEventListener('pushState', () => {
        this.sendPv();
      });
    },
    wr(type: string) {
      const origin = history[type];
      if (typeof origin !== 'function' || !(/native code/.test(origin.toString()))) {
        return false;
      }
      return function () {
        // @ts-ignore
        const res = origin.apply(this, arguments);
        const event = new Event(type);
        window.dispatchEvent(event);
        return res;
      };
    },
    sendPv() {
      // 发送PV
      this.$walk((aegis: Core) => {
        aegis.send({
          url: `${aegis.config.whiteListUrl}?from=${encodeURIComponent(location.href)}`,
          beanFilter: ['from'],
        });
      });
    },
  });
}

export default plugin;
