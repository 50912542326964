// 该插件收集各种cgi请求的日志
import Core, {
  Plugin,
  SpeedLog,
  NormalLog,
  LOG_TYPE,
  StaticAssetsLog,
  Config,
  formatUrl,
  urlIsHttps,
  isRequestAsset,
  tryToGetRetCode,
  ReportDefaultVal,
  PayloadXHR,
  PayloadFetch,
} from '@tencent/aegis-core';

let plugin = new Plugin({ name: 'reportApiSpeed' });

if (CGI_SPEED) {
  plugin = new Plugin({
    name: 'reportApiSpeed',
    override: false,
    onNewAegis(aegis: Core, config) {
      if (!this.override) {
        this.override = true;
        // 改写fetch、xhr获取cgi日志，同一个应用只需要重写一次
        this.overrideFetch(aegis.config);
        this.overrideXhr(aegis.config);
      }
    },
    // 重写fetch方法
    overrideFetch(config: Config) {
      if (typeof window.fetch !== 'function') return;
      const originFetch = window.fetch;
      const self = this;
      window.fetch = function aegisFakeFetch(url: string, option) {
        const sendTime = Date.now();

        return originFetch(url, option)
          .then((res) => {
            try {
              const contentType = res.headers
                ? res.headers.get('content-type')
                : '';
              // 请求失败时content-type经常会是text/plain，所以当失败时一律认为是cgi请求
              if (
                !res.ok || typeof contentType !== 'string' || !isRequestAsset(contentType)
              ) {
                // cgi
                res.clone()
                  .text()
                  .then((data: string) => {
                    // 上报接口返回数据
                    self.publishApiRes({
                      msg: `${url} ${data}`,
                      level: LOG_TYPE.API_RESPONSE,
                    });
                    const ret = tryToGetRetCode(data, config.api);
                    const log: SpeedLog = {
                      url: res.url,
                      isHttps: urlIsHttps(res.url),
                      method: (option?.method) || 'get',
                      duration: Date.now() - sendTime,
                      type: 'fetch',
                      ret: ret || 'unknown',
                      status: res.status,
                      payload: new PayloadFetch(res, data),
                    };
                    // 上报测速日志
                    self.publishSpeed(log);
                    // 如果errCode存在，判断ret是否是errCode中的一个，如果是则上报错误。
                    let errCode: any = config.api && config.api.errCode;
                    errCode = errCode && [].concat(errCode);
                    // 如果api.code存在，则判断ret是否为api code中的一个 否则非0非unknown则认为错误
                    let code: any = config.api && config.api.code;
                    code = code && [].concat(code);
                    const retCodeHandler = config.api && typeof config.api.retCodeHandler === 'function' && config.api.retCodeHandler; // 提供一个钩子函数
                    let isErrMsg = false;
                    if (retCodeHandler) {
                      // 存在retCodeHandler,此时控制权全部交给用户，用户必须返回code: string和isErr: boolean
                      const { code, isErr } = retCodeHandler(data);
                      log.ret = code;
                      isErrMsg = isErr;
                    } else {
                      // 不存在还是走之前逻辑
                      isErrMsg =  (errCode && errCode.indexOf(ret) !== -1) || (code && code.indexOf(ret) === -1) || (!errCode && !code && ret !== '0' && ret !== 'unknown');
                    }
                    // 上报retcode错误日志
                    isErrMsg &&  self.publishApiRes({
                      msg: `request url: ${url} \n response: ${data}`,
                      level: LOG_TYPE.RET_ERROR,
                    });
                  });
              } else {
                // 静态资源
                const log: StaticAssetsLog = {
                  url: res.url,
                  isHttps: urlIsHttps(res.url),
                  method: (option?.method) || 'get',
                  duration: Date.now() - sendTime,
                  type: 'static',
                  status: res.status,
                  urlQuery: formatUrl(res.url, true),
                  x5ContentType: ReportDefaultVal.string as string,
                  x5HttpStatusCode: ReportDefaultVal.number as number,
                  x5ImgDecodeStatus: ReportDefaultVal.number as number,
                  x5ErrorCode: ReportDefaultVal.number as number,
                  x5LoadFromLocalCache: ReportDefaultVal.number as number,
                  x5ContentLength: ReportDefaultVal.number as number,
                  domainLookup: ReportDefaultVal.number as number,
                  connectTime: ReportDefaultVal.number as number,
                };
                // 上报测速日志
                self.publishSpeed(log);
              }
            } catch (e) {}

            // 原封不动返回res
            return res;
          })
          .catch((err) => {
            // 发生错误：跨域、链接有错、
            const log: SpeedLog = {
              url,
              isHttps: urlIsHttps(url as string),
              method: (option?.method) || 'get',
              duration: Date.now() - sendTime,
              type: 'fetch',
              status: 600,
            };
            self.publishSpeed(log);

            // 原封不动继续抛出err
            throw err;
          });
      };
    },
    // 重写XHR
    overrideXhr(config: Config) {
      const xhrProto = window.XMLHttpRequest.prototype;
      const originOpen = xhrProto.open;
      const originSend = xhrProto.send;
      const self = this;
      xhrProto.open = function aegisFakeXhrOpen() {
        if (!this.aegisMethod || !this.aegisUrl) {
          this.aegisMethod = arguments[0];
          this.aegisUrl = arguments[1];
        }

        return originOpen.apply(this, arguments);
      };

      // 改写send
      xhrProto.send = function aegisFakeXhrSend() {
        const sendTime = Date.now();
        let duration = 0;
        !this.__sendByAegis
        && this.addEventListener('loadend', function aegisXhrLoadendHandler() {
          const url = this.aegisUrl;
          if (!url) return;

          duration = Date.now() - sendTime;
          const speedLog: SpeedLog = {
            url,
            isHttps: urlIsHttps(url),
            status: this.status,
            method: this.aegisMethod || 'get',
            type: 'fetch',
            duration,
            payload: new PayloadXHR(this),
          };

          // 根据content-type判断请求资源是静态资源还是cgi
          // 请求失败时content-type经常会是text/plain，所以当失败时一律认为是cgi请求
          const contentType = this.getResponseHeader('content-type');
          if (
            this.status >= 400
                || typeof contentType !== 'string'
                || !isRequestAsset(contentType)
          ) {
            // cgi
            try {
              self.publishApiRes({
                msg: `req url: ${url} \nres time: ${duration} \nres data: ${this.response}`,
                level: LOG_TYPE.API_RESPONSE,
              });
              speedLog.ret = tryToGetRetCode(this.response, config.api);

              // 如果errCode存在，判断ret是否是errCode中的一个，如果是则上报错误。
              let errCode: any = config.api && config.api.errCode;
              errCode = errCode && [].concat(errCode);
              // 如果api.code存在，则判断ret是否为api code中的一个 否则非0非unknown则认为错误
              let code: any = config.api && config.api.code;
              code = code && [].concat(code);
              const retCodeHandler = config.api && typeof config.api.retCodeHandler === 'function' && config.api.retCodeHandler; // 提供一个钩子函数
              let isErrMsg = false;
              if (retCodeHandler) {
                // 存在retCodeHandler,此时控制权全部交给用户，用户必须返回code: string和isErr: boolean
                const { code, isErr } = retCodeHandler(this.response);
                speedLog.ret = code;
                isErrMsg = isErr;
              } else {
                // 不存在还是走之前逻辑
                isErrMsg =  (errCode && errCode.indexOf(speedLog.ret) !== -1) || (code && code.indexOf(speedLog.ret) === -1) || (!errCode && !code && speedLog.ret !== '0' && speedLog.ret !== 'unknown');
              }
              // 上报retcode错误日志
              isErrMsg &&  self.publishApiRes({
                msg: `request url: ${url} \n response: ${this.response}`,
                level: LOG_TYPE.RET_ERROR,
              });
            } catch (e) {
              speedLog.ret = 'unknown';
            }
          } else {
            // 静态资源
            speedLog.type = 'static';
            delete speedLog.ret;
            Object.assign(speedLog, {
              urlQuery: formatUrl(url, true),
              x5ContentType: ReportDefaultVal.string as string,
              x5HttpStatusCode: ReportDefaultVal.number as number,
              x5ImgDecodeStatus: ReportDefaultVal.number as number,
              x5ErrorCode: ReportDefaultVal.number as number,
              x5LoadFromLocalCache: ReportDefaultVal.number as number,
              x5ContentLength: ReportDefaultVal.number as number,
              domainLookup: ReportDefaultVal.number as number,
              connectTime: ReportDefaultVal.number as number,
            });
          }

          self.publishSpeed(speedLog);
        });

        return originSend.apply(this, arguments);
      };
    },
    // 分发测速日志
    publishSpeed(log: SpeedLog) {
      // 去掉query部分
      log.url = formatUrl(log.url);
      this.$walk((aegis: Core) => {
        const pluginConfig = this.$getConfig(aegis);
        // restful风格的接口可以用这个方法来聚合
        if (log.type === 'fetch' && pluginConfig && typeof pluginConfig.urlHandler === 'function') {
          aegis._speedLogPipeline({
            ...log,
            url: encodeURIComponent(pluginConfig.urlHandler(log.url, log.payload)),
          });
          return;
        }
        aegis._speedLogPipeline(log);
      });
    },
    // 分发api返回值
    publishApiRes(log: NormalLog) {
      this.$walk((aegis: Core) => {
        aegis._normalLogPipeline(log);
      });
    },
  });
}

export default plugin;
