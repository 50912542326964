import Core from './core';

type PluginConfig = boolean | any;
interface PluginOption<Aegis> {
  name: string;
  init?: (this: PluginOption<Aegis> & PluginMethod<Aegis>, config: PluginConfig) => void;
  onNewAegis?: (this: PluginOption<Aegis> & PluginMethod<Aegis>, aegis: Aegis, option: PluginConfig) => void;
  [key: string]: any;
}

interface PluginMethod<Aegis> {
  $walk: (cb: (aegis: Aegis, config: PluginConfig) => void) => void;
  $getConfig: (aegis: Aegis) => PluginConfig;
  [key: string]: any;
}

export default class Plugin <Aegis = Core> {
  // 插件标识符
  __aegisPlugin = true;
  name = '';
  _option: PluginOption<Aegis>;
  private _instances: Aegis[] = [];
  // 插件初始化flag
  private _inited = false;

  constructor(option: PluginOption<Aegis>) {
    option.$walk = this._walk.bind(this);
    option.$getConfig = this._getConfig.bind(this);
    this._option = option;
    this.name = option.name;
  }
  patch(aegis: Aegis) {
    // 配置打开 && 不存在
    if (this._canUse(aegis) && this._exist(aegis)) {
      this._triggerInit(aegis);
      this._instances.push(aegis);
      this._triggerOnNewAegis(aegis);
    }
  }

  private _walk(cb: (aegis: Aegis, config: PluginConfig) => void) {
    this._instances.forEach((instance) => {
      const config = this._canUse(instance);
      if (config) {
        cb(instance, config);
      }
    });
  }
  // 获取插件是否开启
  private _canUse(aegis: Aegis): boolean {
    const config = this._getConfig(aegis);
    if (config && typeof config === 'object') {
      return true;
    }
    return !!config;
  }
  // 获取插件配置
  private _getConfig(aegis: Aegis): PluginConfig {
    // @ts-ignore
    return aegis.config[this.name];
  }
  private _exist(aegis: Aegis): boolean {
    return this._instances.indexOf(aegis) === -1;
  }
  private _triggerInit(aegis: Aegis) {
    if (!this._inited) {
      this._inited = true;
      // 使用this._option作为调用，在插件中可以使用this来使用内部方法
      this._option.init && this._option.init.call(this._option, this._getConfig(aegis));
    }
  }
  private _triggerOnNewAegis(aegis: Aegis) {
    this._option.onNewAegis && this._option.onNewAegis.call(this._option, aegis, this._getConfig(aegis));
  }
}
