import {
  onError,
  assetSpeed,
  pagePerformance,
  cgiSpeed,
  aid,
  tjg,
  device,
  offlineLog,
  shadowLog,
  spa,
} from './plugins';

import Aegis from './aegis';

// 错误收集
if (ON_ERROR) {
  Aegis.use(onError);
}
// cgi测速
if (CGI_SPEED) {
  Aegis.use(cgiSpeed);
}
// 静态资源收集
if (ASSET_SPEED) {
  Aegis.use(assetSpeed);
}
// 页面性能
if (PAGE_PERFORMANCE) {
  Aegis.use(pagePerformance);
}
// aid
Aegis.use(aid);
// 天机阁全链路日志.
if (TJG) {
  Aegis.use(tjg);
}
// 设备信息
Aegis.use(device);
// 离线日志
if (OFFLINE_LOG) {
  Aegis.use(offlineLog);
}
// 用户回放
if (SHADOW_LOG) {
  Aegis.use(shadowLog);
}
// 单页应用
if (SPA) {
  Aegis.use(spa);
}

export default Aegis;
