export enum LOG_TYPE {
    INFO_ALL = '-1', // 这个类型不会被直接上报到后端，会在 whitelistPipe 中被转为INFO
    API_RESPONSE = '1', // 白名单中的用户，页面上的所有 API 返回都将会被上报
    INFO = '2',
    ERROR = '4',
    PROMISE_ERROR = '8',
    AJAX_ERROR = '16',
    SCRIPT_ERROR = '32',
    IMAGE_ERROR = '64',
    CSS_ERROR = '128',
    CONSOLE_ERROR = '256',
    MEDIA_ERROR = '512',
    RET_ERROR = '1024',
    REPORT = '2048', // 与 error 相同，会触发告警，但是不会扣分
    PV = '4096' // pv 入库标示
}

export interface NormalLog {
    msg: any;
    level: LOG_TYPE;
    [key: string]: any;
}

export interface SpeedLog {
    url: string; // 请求地址,
    isHttps: boolean; // 请求地址是否https
    method: string; // 请求方法
    type: 'static' | 'fetch'; // static 静态资源测速  fetch cgi测速
    duration?: number; // 耗时
    ret?: string | 'unknown'; // cgi 的状态码，如果是图片或其他的，则没有该字段
    status?: number; // http 返回码（静态资源的话成功200，失败400）
    payload?: object; // 额外数据，包含了cgi请求的XMLHttpRequest或response（注意：静态资源没有这个属性）
    [key: string]: any;
}

export interface PagePerformanceLog {
    dnsLookup: number;
    tcp: number;
    ssl: number;
    ttfb: number;
    contentDownload: number;
    domParse: number;
    resourceDownload: number;
    firstScreenTiming: number;
    [key: string]: any;
}

export interface StaticAssetsLog {
    url: string; // 资源地址（无参数）,
    isHttps: boolean; // 请求地址是否https
    method: string; // 请求方法
    type: 'static' | 'fetch'; // static 静态资源测速  fetch cgi测速
    duration?: number; // 耗时
    ret?: string | 'unknown'; // cgi 的状态码，如果是图片或其他的，则没有该字段
    status?: number; // http 返回码（静态资源的话成功200，失败400）
    x5ContentType: string;  // 资源格式
    x5HttpStatusCode: number;
    x5ImgDecodeStatus: number;
    x5ErrorCode: number;
    x5LoadFromLocalCache: number;
    x5ContentLength: number;
    domainLookup: number;  // DNS解析耗时（domainLookupEnd - domainLookupStart）
    connectTime: number; // connectEnd - connectStart
    x5Type?: number; // 是否新版X5
    urlQuery: string;  // 资源请求参数（a=1&b=2）
}
