import { Config } from '../interface';

export const getDefaultConfig = (): Config => ({
  version: 0,
  delay: 1500,
  onError: true,
  repeat: 5,
  random: 1,
  aid: true,
  device: true,
  pagePerformance: true,
  url: 'https://aegis.qq.com/collect',
  speedUrl: 'https://aegis.qq.com/speed',
  customTimeUrl: 'https://aegis.qq.com/speed/custom',
  whiteListUrl: 'https://aegis.qq.com/aegis/whitelist',
  performanceUrl: 'https://aegis.qq.com/speed/performance',
  listenOnerror: true,
});
