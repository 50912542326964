import { Plugin } from '@tencent/aegis-core';

export default new Plugin({
  name: 'aid',
  aid: '',
  init() {
    // 某些情况下操作 localStorage 会报错.
    try {
      let aid = window.localStorage.getItem('AEGIS_ID');
      if (!aid) {
        aid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
          /[xy]/g,
          (c) => {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
          }
        );
        window.localStorage.setItem('AEGIS_ID', aid);
      }
      this.aid = aid;
    } catch (e) {}
  },
  onNewAegis(aegis) {
    aegis.bean.aid = this.aid;
  },
});
