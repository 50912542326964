import { Pipe } from './index';
import { Config } from '../interface';

// 写入前做一些处理
export function createWriteReportPipe(emit: Function, config: Config): Pipe {
  return function (msg, resolve) {
    const { logCreated } = config;
    if (typeof logCreated === 'function') {
      const logs = msg.filter((log: any) => logCreated(log) !== false);
      emit('beforeWrite', logs);
      return resolve(logs);
    }
    emit('beforeWrite', msg);
    return resolve(msg);
  };
}
