import { Pipe } from './index';
import { Config } from '../interface';

// 节流，下一个pipe将接收到一个数组
export function createThrottlePipe(config: Config, maxLength?: number): Pipe {
  let timer: any;
  const msgs: any[] = [];

  return function (msg, resolve) {
    msgs.push(msg);

    if (maxLength && msgs.length >= maxLength) {
      resolve(msgs.splice(0, msgs.length));
      timer && clearTimeout(timer);
      return;
    }

    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      resolve(msgs.splice(0, msgs.length));
    }, config.delay || 1000);
  };
}
