import { NormalLog } from '@tencent/aegis-core';
import { stringifyPlus } from '../util';
import { Pipe } from './index';

// 生成格式日志的pipe
export const formatNormalLogPipe: Pipe = (
  log: NormalLog | NormalLog[],
  resolve
) => {
  if (Array.isArray(log)) {
    return resolve(log.map(log => ({
      msg:
                        typeof log.msg === 'string'
                          ? log.msg
                          : [].concat(log.msg).map(stringifyPlus)
                            .join(' '),
      level: log.level,
    })));
  }
  return resolve({
    msg: typeof log.msg === 'string' ? log.msg : stringifyPlus(log.msg),
    level: log.level,
  });
};
