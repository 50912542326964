import { Pipe } from './index';
import { SpeedLog } from '@tencent/aegis-core';

// 限制每条测速只上报一次
export function createSpeedRepeatLimitPipe(): Pipe {
  const logMap: { [key: string]: any } = {};

  return (log: SpeedLog | SpeedLog[], resolve) => {
    if (Array.isArray(log)) {
      const filterLog = log.filter((log) => {
        const through = !logMap[log.url];
        logMap[log.url] = true;
        return through;
      });
      filterLog.length && resolve(filterLog);
    } else {
      !logMap[log.url] && resolve(log);
      logMap[log.url] = true;
    }
  };
}
