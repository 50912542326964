import { Plugin } from '@tencent/aegis-core';

declare global {
    interface Navigator {
        connection: any;
    }
}
export enum NetworkTypeNum {
    unknown = 100,
    wifi = 1,
    net2g,
    net3g,
    net4g,
    net5g,
    net6g,
}
enum PlatTypeNum {
    android = 1,
    ios = 2,
    other = 100,
}
enum X5TypeNum {
    oldX5 = 1,
    newX5,
    other,
}
const ua = navigator.userAgent;
export default new Plugin({
  name: 'device',
  onNewAegis(aegis) {
    if (aegis.config.reportAssetSpeed) {
      aegis.extendBean('platform', this.getPlatform());
      aegis.extendBean('x5Type', this.getX5Type());
      aegis.extendBean('netType', NetworkTypeNum.unknown);
      this.refreshNetworkTypeToBean(aegis);
    }
  },
  // 获取设备类型
  getPlatform(): PlatTypeNum {
    const REGEXP_ANDROID = /\bAndroid\s*([^;]+)/;
    const REGEXP_IOS = /\b(iPad|iPhone|iPod)\b.*? OS ([\d_]+)/;
    let platform = PlatTypeNum.other;

    // 平台： 1-android; 2-iOS; 100- 其他
    if (REGEXP_ANDROID.test(ua)) {
      platform = PlatTypeNum.android;
    } else if (REGEXP_IOS.test(ua)) {
      platform = PlatTypeNum.ios;
    }
    return platform;
  },
  // 获取x5类型
  getX5Type(): X5TypeNum {
    let x5Type = X5TypeNum.other;
    if (ua.indexOf('tbs') >= 0) {
      const tbsVer = ua.match(/tbs\/([\d\.]+)/);
      if (tbsVer?.[1] && parseInt(tbsVer[1], 10) >= 36541) {
        x5Type = X5TypeNum.newX5;
      } else {
        x5Type = X5TypeNum.oldX5;
      }
    }

    return x5Type;
  },
  // 根据网络变化刷新网络类型
  refreshNetworkTypeToBean(context: any) {
    const { config } = context;
    let getNetworkTypeInterface: Function = getNetworkType;
    if (typeof config.getNetworkType === 'function') {
      getNetworkTypeInterface = config.getNetworkType;
    }
    getNetworkTypeInterface((type: NetworkTypeNum) => {
      if (!NetworkTypeNum[type]) {
        type = NetworkTypeNum.unknown;
      }
      context.extendBean('netType', type);
      // 每隔3秒更新一次
      this.NetworkRefreshTimer = setTimeout(() => {
        this.refreshNetworkTypeToBean(context);
        clearTimeout(this.NetworkRefreshTimer);
      }, 3000);
    });
  },
});

export function getNetworkType(callback: Function) {
  function parseNumberType(net: string) {
    net = String(net).toLowerCase();
    if (net.indexOf('2g') >= 0) return NetworkTypeNum.net2g;
    if (net.indexOf('3g') >= 0) return NetworkTypeNum.net3g;
    if (net.indexOf('4g') >= 0) return NetworkTypeNum.net4g;
    if (net.indexOf('5g') >= 0) return NetworkTypeNum.net5g;
    if (net.indexOf('6g') >= 0) return NetworkTypeNum.net6g;
    if (net.indexOf('wifi') >= 0) return NetworkTypeNum.wifi;
    return NetworkTypeNum.unknown;
  }

  const netType: string = (navigator.connection && navigator.connection.type) ? navigator.connection.type : 'unknown';
  callback(parseNumberType(netType));
}
