import Core, { Plugin, NormalLog, buildLogParam, LOG_TYPE, stringifyPlus, Config } from '@tencent/aegis-core';
import Aegis, { WebConfig } from '../aegis';

let plugin = new Plugin({ name: 'offlineLog' });


function loadFlog(scriptUrl: string, config: WebConfig | Config) {
  let collect: any = [];
  let upload: any = null;
  function collector(log: any) {
    collect = collect.concat(log);
  }
  function uploader(conds: any = {}, params: any = {}) {
    upload = { conds, params };
  }
  Aegis.useAsyncPlugin(scriptUrl, {
    exportsConstructor: 'Flog',
    onAegisInit: (aegis: Core) => {
      aegis.lifeCycle.on('beforeWrite', collector);
      aegis.lifeCycle.on('uploadLogs', uploader);
    },
    onAegisInitAndPluginLoaded: (aegis: Aegis, ExportsConstructor: any) => {
      const {
        dbConfig = {},
        url = Aegis.urls.aegisCollect,
        offlineLogExp = 3,
        id,
        uin,
      } = config;
      // if (!uin) return; // 没有uin也可以写入
      aegis.lifeCycle.remove('beforeWrite', collector);
      aegis.lifeCycle.remove('uploadLogs', uploader);
      const options = Object.assign(
        {
          lookupUrl: `${url}/offlineAuto?id=${id}&uin=${uin}`,
          // uploadUrl: `${url}/offlineLogV2`,
          preservedDay: offlineLogExp,
          id,
          uin,
        },
        dbConfig,
        { sessionId: Aegis._sessionID }
      );
      try {
        const flog = new ExportsConstructor(options);
        collect.forEach((e: NormalLog) => {
          flog.add({ ...e, level: LOG_TYPE.INFO_ALL ? LOG_TYPE.INFO : e.level });
        });
        aegis.lifeCycle.on('beforeWrite', (logs: NormalLog[] = []) => {
          logs.forEach((e: NormalLog) => {
            flog.add({ ...e, level: LOG_TYPE.INFO_ALL ? LOG_TYPE.INFO : e.level });
          });
        });
        aegis.lifeCycle.on(
          'uploadLogs',
          (conds: any = {}, params: any = {}) => {
            const _conds = Object.assign(
              {
                id: config.id,
                uin: config.uin,
              },
              conds
            );
            flog.uploadLogs(_conds, params);
          }
        );
        aegis.lifeCycle.on('onConfigChange', (config: WebConfig) => {
          flog.setConfig(config);
        });

        flog.on('PERREVENT', (err: any) => {
          aegis.send({ data: buildLogParam({ msg: stringifyPlus(err), level: LOG_TYPE.INFO }), contentType: 'application/x-www-form-urlencoded', method: 'post', addBean: false, url: `${config.url}?id=893&sessionId=${Aegis._sessionID}&uin=${config.uin}&from=${config.id}&count=1&version=${__VERSION__}` });
        });
        if (upload) {
          aegis.lifeCycle.emit(
            'uploadLogs',
            upload.conds,
            upload.params
          );
          upload = null;
        }
        aegis.flog = flog; // 将flog对用户公开，让用户可以自由操作离线日志
      } catch (e) {
        console.log(e);
      }
    },
  });
}
if (OFFLINE_LOG) {
  plugin = new Plugin({
    name: 'offlineLog',
    onNewAegis(aegis) {
      loadFlog(Aegis.urls.flog, aegis.config);
    },
  });
}

export default plugin;
