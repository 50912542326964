import Core, {
  Plugin,
  StaticAssetsLog,
  canUseResourceTiming,
  formatUrl,
  urlIsHttps,
  ReportDefaultVal,
  getReportVal,
} from '@tencent/aegis-core';

declare global {
  interface StaticPerformanceResourceTiming extends PerformanceResourceTiming {
    x5ContentType?: string;  // 资源格式
    x5HttpStatusCode?: number;
    x5ImgDecodeStatus?: number;
    x5ErrorCode?: number;
    x5LoadFromLocalCache?: number;
    x5ContentLength?: number;
  }
}

let plugin = new Plugin({ name: 'reportAssetSpeed' });

if (ASSET_SPEED) {
  plugin = new Plugin({
    name: 'reportAssetSpeed',
    collectCur: 0,
    ASSETS_INITIATOR_TYPE: [
      'img',
      'css',
      'script',
      'link',
      'audio',
      'video',
    ],
    init() {
      if (!canUseResourceTiming()) return;
      // collect只执行一次，但每次collect到的数据都将分发至各个实例的管道中
      this.collectFailLog();
      setInterval(this.collectSuccLog.bind(this), this.getDelay());
      // 满了就清零
      performance.onresourcetimingbufferfull = () => {
        this.collectCur = 0;
        performance.clearResourceTimings();
      };
    },
    // 获取delay
    getDelay() {
      let delay = 0;
      this.$walk((aegis: Core)  => {
        delay = aegis.config.delay || 0;
      });
      return delay;
    },
    // 分发日志
    publish(msg: StaticAssetsLog | StaticAssetsLog[]) {
      this.$walk((aegis: Core)  => {
        // @ts-ignore
        aegis._speedLogPipeline(msg);
      });
    },
    // 收集静态资源成功日志
    collectSuccLog() {
      const allEntries = performance.getEntriesByType('resource');
      const entries = allEntries.slice(this.collectCur);
      this.collectCur = allEntries.length;

      for (let i = 0, l = entries.length; i < l; i++) {
        const entry = entries[i] as StaticPerformanceResourceTiming;
        // 只收集静态资源 && 屏蔽aegis-sdk
        if (
          this.ASSETS_INITIATOR_TYPE.indexOf(entry.initiatorType) !== -1
          && entry.name.indexOf('cdn-go.cn/aegis/aegis-sdk') === -1
        ) {
          this.publish(this.generateLog(entry));
        }
      }
    },
    // 收集静态资源失败日志
    collectFailLog() {
      window.document.addEventListener(
        'error',
        (event) => {
          if (!event || !event.target || !event.srcElement) return;
          const target = event.target || event.srcElement;
          const url = target.src || target.href;
          // 将错误上报到资源测速
          if (url) {
            const failedLog: StaticAssetsLog = {
              url: formatUrl(url),
              status: 400,
              method: 'get',
              type: 'static',
              isHttps: urlIsHttps(url),
              urlQuery: formatUrl(url, true),
              x5ContentType: ReportDefaultVal.string as string,
              x5HttpStatusCode: ReportDefaultVal.number as number,
              x5ImgDecodeStatus: ReportDefaultVal.number as number,
              x5ErrorCode: ReportDefaultVal.number as number,
              x5LoadFromLocalCache: ReportDefaultVal.number as number,
              x5ContentLength: ReportDefaultVal.number as number,
              domainLookup: ReportDefaultVal.number as number,
              connectTime: ReportDefaultVal.number as number,
            };
            this.publish(failedLog);
          }
        },
        true
      );
    },
    // 格式化日志
    generateLog(entry: StaticPerformanceResourceTiming): StaticAssetsLog {
      return {
        url: formatUrl(entry.name),
        method: 'get',
        duration: Number(entry.duration.toFixed(2)),
        status: 200, // resourceTiming 获取到的资源都是请求成功的
        type: 'static',
        isHttps: urlIsHttps(entry.name),
        urlQuery: formatUrl(entry.name, true),
        x5ContentType: getReportVal(entry.x5ContentType, true) as string,
        x5HttpStatusCode: getReportVal(entry.x5HttpStatusCode) as number,
        x5ImgDecodeStatus: getReportVal(entry.x5ImgDecodeStatus) as number,
        x5ErrorCode: getReportVal(entry.x5ErrorCode) as number,
        x5LoadFromLocalCache: typeof entry.x5LoadFromLocalCache === 'undefined'
          ? ReportDefaultVal.number : (entry.x5LoadFromLocalCache | 0),
        x5ContentLength: entry.encodedBodySize || getReportVal(entry.x5ContentLength) as number,
        domainLookup: getReportVal(entry.domainLookupEnd - entry.domainLookupStart) as number,
        connectTime: getReportVal(entry.connectEnd - entry.connectStart) as number,
      };
    },
  });
}

export default plugin;
