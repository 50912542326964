import { getDefaultConfig } from './constant';
import { InterfaceEventEmiter, EventEmiter, buildLogParam, stringifyPlus } from './util';
import { Config, LOG_TYPE, SendOption, SendSuccess, SendFail, SpeedLog } from './interface';
import Plugin from './plugin';
import { createThrottlePipe, createPipeline, formatNormalLogPipe, createWhitelistPipe, createWriteReportPipe } from './pipes';

export default class Core {
  static LOG_TYPE = LOG_TYPE;
  // 插件
  static installedPlugins: Plugin[] = [];
  static use(plugin: Plugin) {
    if (Core.installedPlugins.indexOf(plugin) === -1 && plugin.__aegisPlugin) {
      Core.installedPlugins.push(plugin);
    }
  }
  // 配置项
  config: Config = getDefaultConfig();
  // 生命周期
  lifeCycle: InterfaceEventEmiter = new EventEmiter();
  // 继承 Core 时需要提供该方法，send 在发送请求时，必须将 bean 中的数据拼接到 url 中
  bean: {[key: string]: string | number} = {};
  // 基础上报
  _normalLogPipeline = createPipeline([
    // 节流，之后的logs都将是数组
    createThrottlePipe(this.config, 5),
    // 格式化
    formatNormalLogPipe,
    // 在日志过滤写入之前处理一些杂事
    createWriteReportPipe(this.lifeCycle.emit, this.config),
    // 白名单过滤，如果用户不在白名单中，将会把API_RESPONSE和INFO两种等级的日志过滤掉。
    createWhitelistPipe(this),
    // 钩子beforeReport，ps: 只有 config 中的 beforeReport 能阻止上报
    (logs, resolve) => {
      const newLogs = JSON.parse(JSON.stringify(logs));
      this.lifeCycle.emit('beforeReport', newLogs);

      const { beforeReport } = this.config;
      if (typeof beforeReport === 'function') {
        logs = logs.filter((log: any) => beforeReport(log) !== false);
      }
      if (logs.length) {
        return resolve(logs);
      }
    },
    // 上报
    (logs) => {
      this.send(
        {
          url: this.config.url || '',
          data: buildLogParam(logs),
          method: 'post',
          contentType: 'application/x-www-form-urlencoded',
        },
        () => {
          const { onReport } = this.config;
          if (typeof onReport === 'function') {
            logs.forEach((log: any) => {
              onReport(log);
            });
          }
        }
      );
    },
  ]);
  private _timeMap: {
    [k: string]: number;
  } = {};

  private _customTimePipeline = createPipeline([
    // 节流
    createThrottlePipe(this.config),
    (logs) => {
      this.send({
        url: `${this.config.customTimeUrl}?payload=${encodeURIComponent(JSON.stringify({ custom: logs }))}`,
      });
    },
  ]);
  constructor(config: Config) {}

  init(config: Config) {
    this.setConfig(config);

    // 执行已经安装的插件
    // ps：这里只能用for循环，因为插件里可能会安装其他插件
    // 如果使用forEach，后续安装的插件将遍历不到
    for (let i = 0; i < Core.installedPlugins.length; i++) {
      try {
        Core.installedPlugins[i].patch(this);
      } catch (e) {
        this._sendSDKError(e);
      }
    }

    this.lifeCycle.emit('onInited');
  }

  // 设置配置
  setConfig(config: Partial<Config>) {
    Object.assign(this.config, config);

    this.bean.id = this.config.id || '';
    this.bean.uin = this.config.uin || '';
    this.bean.version = this.config.version || __VERSION__;
    this.lifeCycle.emit('onConfigChange', config);
    return this.config;
  }

  // 扩展Bean数据
  extendBean(key: string, value: any) {
    this.bean[key] = value;
  }
  // eslint-disable-next-line
  send(options?: SendOption, success?: SendSuccess, fail?: SendFail) {
    throw new Error('You need to override "send" method');
  }

  // SDK 发生错误时上报方法
  _sendSDKError(err: any) {
    this.send({
      url: `https://aegis.qq.com/collect?id=1085&msg[0]=${encodeURIComponent(stringifyPlus(err))}&level[0]=2&from=${
        this.config.id
      }&count=1&version=${this.config.id}(${__VERSION__})`,
      addBean: false,
      method: 'get',
    });
  }

  _speedLogPipeline(log?: SpeedLog) {
    throw new Error('You need to override "speedLogPipeline" method');
  }
  info(...msg: any) {
    this._normalLogPipeline({
      msg,
      level: LOG_TYPE.INFO,
    });
  }
  infoAll(...msg: any) {
    this._normalLogPipeline({
      msg,
      level: LOG_TYPE.INFO_ALL,
    });
  }
  report(...msg: any) {
    this._normalLogPipeline({
      msg,
      level: LOG_TYPE.REPORT,
    });
  }

  // 自定义PV
  reportPv(id: number) {
    if (!id) return;

    const baseQuery = `${
      Object.getOwnPropertyNames(this.bean)
        .filter(key => key !== 'id')
        .map(key => `${key}=${this.bean[key]}`)
        .join('&')
    }`;

    this.send({
      url: `${this.config.url}/${id}?${baseQuery}`,
      // 不能拼接bean上去，否则bean里面的id会覆盖链接中的id
      addBean: false,
    });
  }

  // 自定义测速
  reportTime(key: string, duration: number) {
    if (typeof key !== 'string') {
      console.warn('reportTime: first param must be a string');
      return;
    }
    if (typeof duration !== 'number') {
      console.warn('reportTime: second param must be number');
      return;
    }
    this._submitCustomTime(key, duration);
  }
  time(key: string) {
    if (typeof key !== 'string') {
      console.warn('time: first param must be a string');
      return;
    }
    if (!this._timeMap[key]) {
      this._timeMap[key] = Date.now();
    } else {
      console.warn(`Timer ${key} already exists`);
    }
  }
  timeEnd(key: string) {
    if (typeof key !== 'string') {
      console.warn('timeEnd: first param must be a string');
      return;
    }
    if (this._timeMap[key]) {
      this._submitCustomTime(key, Date.now() - this._timeMap[key]);
      delete this._timeMap[key];
    } else {
      console.warn(`Timer ${key} does not exist`);
    }
  }
  private _submitCustomTime(name: string, duration: number) {
    this._customTimePipeline({
      name,
      duration,
    });
  }
}
