interface Params {
    [k: string]: any;
}
export const buildParam = (url: string, param: Params): string => {
  if (typeof url !== 'string') return '';

  if (typeof param === 'object' && param) {
    const paramStr = Object.getOwnPropertyNames(param)
      .map((key) => {
        const v = param[key];
        return `${key}=${
          typeof v === 'string'
            ? encodeURIComponent(v)
            : encodeURIComponent(JSON.stringify(v))
        }`;
      })
      .join('&')
      .replace(/eval/gi, 'evaI');
    return `${url}${url.indexOf('?') === -1 ? '?' : '&'}${paramStr}`;
  }
  return url;
};
