import Core, { Plugin, PagePerformanceLog, canUseResourceTiming } from '@tencent/aegis-core';

interface Change {
    roots: Element[];
    rootsDomNum: number[];
    time: number;
}

let plugin = new Plugin({ name: 'pagePerformance' });

if (PAGE_PERFORMANCE) {
  let result: PagePerformanceLog;
  plugin = new Plugin({
    name: 'pagePerformance',
    onNewAegis(aegis: Core) {
      if (!canUseResourceTiming()) return;
      if (result) {
        this.publish(result, aegis);
      } else {
        try {
          this.getFirstScreenTiming((firstScreenTiming: number) => {
            const t: PerformanceTiming = performance.timing;
            // @todo 这里不知道为什么有时候 t.loadEventStart - t.domInteractive 返回一个很大的负数，暂时先简单处理
            let resourceDownload = t.loadEventStart - t.domInteractive;
            if (resourceDownload < 0) resourceDownload = 1070;
            result = {
              dnsLookup: t.domainLookupEnd - t.domainLookupStart,
              tcp: t.connectEnd - t.connectStart,
              ssl:
                              t.secureConnectionStart === 0
                                ? 0
                                : t.requestStart - t.secureConnectionStart,
              ttfb: t.responseStart - t.requestStart,
              contentDownload: t.responseEnd - t.responseStart,
              domParse: t.domInteractive - t.domLoading,
              resourceDownload,
              firstScreenTiming: Math.floor(firstScreenTiming),
            };
            this.publish(result, aegis);
          });
        } catch (e) {}
      }
    },
    publish(log: PagePerformanceLog, instance: any) {
      const param: string[] = [];
      for (const key in log) {
        param.push(`${key}=${log[key]}`);
      }
      const pluginConfig = this.$getConfig(instance);
      if (typeof pluginConfig.urlHandler === 'function') {
        instance.send({
          url: `${instance.config.performanceUrl}?${param.join('&')}&from=${encodeURIComponent(pluginConfig.urlHandler()) || window.location.href}`,
          beanFilter: ['from'],
        });
      } else {
        instance.send({
          url: `${instance.config.performanceUrl}?${param.join('&')}`,
        });
      }
    },
    getFirstScreenTiming(callback: Function) {
      const ignoreEleList: string[] = ['script', 'style', 'link', 'br'];
      const changeList: Change[] = [];
      const self = this;
      const observeDom: MutationObserver = new MutationObserver((mutations) => {
        // 由于一次“MutationObserver”回调函数包含了多个“mutaion”，多个“mutation”可能修改了同一个dom节点，
        // 如果不聚合直接算dom节点数量的话，将重复计算
        const change: Change = {
          roots: [],
          rootsDomNum: [],
          time: performance.now(),
        };
        mutations.forEach((mutation) => {
          if (
            !mutation
                      || !mutation.addedNodes
                      || !mutation.addedNodes.forEach
          ) return;

          mutation.addedNodes.forEach((ele) => {
            if (
              ele.nodeType === 1
                          && ignoreEleList.indexOf(ele.nodeName.toLocaleLowerCase())
                          === -1
                          && !self.isEleInArray(ele as Element, change.roots)
            ) {
              change.roots.push(ele as Element);
              change.rootsDomNum.push(self.walkAndCount(ele as Element) || 0);
            }
          });
        });
        change.roots.length && changeList.push(change);
      });
      observeDom.observe(document, {
        childList: true,
        subtree: true,
      });
      setTimeout(() => {
        observeDom.disconnect();
        let maxChange = 0;
        let firstScreenTiming = 0;
        let hasImage = false;

        changeList.forEach((change: Change) => {
          for (let i = 0; i < change.roots.length; i++) {
            if (
              change.rootsDomNum[i] > maxChange
                          && self.isInFirstScreen(change.roots[i])
            ) {
              maxChange = change.rootsDomNum[i];
              firstScreenTiming = change.time;
            }
          }
        });

        // 用户手动标记的需要检查首屏的dom
        const fpCheckDomList = document.querySelectorAll('.fp-check');
        const checkSrc: string[] = [];
        for (let i = 0; i < fpCheckDomList.length; i++) {
          if (fpCheckDomList[i].tagName.toLowerCase() === 'img' && (fpCheckDomList[i] as HTMLImageElement).src !== '') {
            checkSrc.push((fpCheckDomList[i] as HTMLImageElement).src);
          } else {
            const style = window.getComputedStyle(fpCheckDomList[i]);
            const matchRes = /url\([\'\"](.+)[\'\"]\)/ig.exec(style.backgroundImage as any); // 提取出 url
            if (matchRes && matchRes.length === 2) {
              checkSrc.push(matchRes[1]);
            }
          }
        }
        // 遍历当前请求的图片中，如果有开始请求时间在首屏dom渲染期间的，则表明该图片是首屏渲染中的一部分，
        // 所以dom渲染时间和图片返回时间中大的为首屏渲染时间
        let maxTime = 0;

        window.performance
          .getEntriesByType('resource')
          .some((resource: PerformanceResourceTiming) => {
            checkSrc.some((src, i) => {
              if (src === resource.name) {
                if (resource.responseEnd > maxTime) {
                  maxTime = resource.responseEnd;
                }

                if (
                  (resource.fetchStart < firstScreenTiming || resource.startTime < firstScreenTiming)
                                  && resource.responseEnd > firstScreenTiming
                ) {
                  hasImage = true;
                  firstScreenTiming = resource.responseEnd;
                }

                checkSrc.splice(i, 1);
                return true;
              }
            });

            return checkSrc.length === 0;
          });

        if (firstScreenTiming === 0) {
          firstScreenTiming = maxTime;
        }

        callback?.(hasImage ? firstScreenTiming : firstScreenTiming + 25);
      }, 3000);
    },
    // 查看当前元素的先祖是否在数组中
    isEleInArray(target: Element | null, arr: Element[]): boolean {
      if (!target || target === document.documentElement) {
        return false;
      } if (arr.indexOf(target) !== -1) {
        return true;
      }
      return this.isEleInArray(target.parentElement, arr);
    },
    // 是否在首屏时间中
    isInFirstScreen(target: Element): boolean {
      if (!target || !target.getBoundingClientRect) return false;

      const rect = target.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      const screenWidth = window.innerWidth;
      return (
        rect.left >= 0
              && rect.left < screenWidth
              && rect.top >= 0
              && rect.top < screenHeight
      );
    },
    // 计算元素数量
    walkAndCount(target: Element): number {
      let eleNum = 0;
      if (target && target.nodeType === 1) {
        eleNum++;
        const { children } = target;
        if (children?.length) {
          for (let i = 0; i < children.length; i++) {
            eleNum += this.walkAndCount(children[i]);
          }
        }
      }
      return eleNum;
    },
  });
}

export default plugin;
