import Core, {
  Config,
  Plugin,
  SendOption,
  SendSuccess,
  SendFail,
  createThrottlePipe,
  createPipeline,
  createSpeedRepeatLimitPipe,
  tryToGetRetCode,
  PayloadXHR,
  PayloadFetch,
} from '@tencent/aegis-core';
import { createSpeedNetworkRefreshPipe } from './pipes/refresh-network';
import { buildParam } from './util/send';
import { speedShim, loadScript } from './util';
// TODO: 配置babel，干掉这里
import './util/polyfill';

// 在此处添加web特有配置
export interface WebConfig extends Config {
    asyncPlugin?: boolean;
    pagePerformance?: boolean | { urlHandler: () => string };
    reportApiSpeed?: boolean | { urlHandler: (url: string, payload: PayloadXHR | PayloadFetch) => string };
    reportAssetSpeed?: boolean;
    beforeReportSpeed?: Function;
    tjg?: boolean;
    offlineLog?: boolean;
    dbConfig?: any;
    offlineLogExp?: number;
    shadowLog?: any;
    getNetworkType?: Function;
}

// 异步加载插件配置
// TODO 下面的 aegis 类型应该为 Aegis！！后面需要改回来
interface AsyncPluginOption {
  // 第三方包导出的构造函数，此方法需要第三方包在script name中获取，然后暴露在window上。
  exportsConstructor?: string;
  onAegisInit?: (aegis: Core) => void;
  onAegisInitAndPluginLoaded?: (
      aegis: Aegis,
      exportsConstructor?: Function
  ) => void;
}

let asyncPluginIndex = 0;
export default class Aegis extends Core {
  // 注入version（从package.json中获取）
  static __version__ = __VERSION__;
  static _sessionID = `session-${Date.now()}`;
  // 异步加载插件
  static _asyncPlugin: { [key: string]: string } = {};
  static useAsyncPlugin(url: string, options: AsyncPluginOption = {}) {
    const {
      exportsConstructor = `aegis-plugin-${asyncPluginIndex++}`,
      onAegisInit = () => {},
      onAegisInitAndPluginLoaded = () => {},
    } = options;
    if (typeof url !== 'string') throw new TypeError('useAsyncPlugin first param must be string');
    if (
      typeof onAegisInit !== 'function'
        || typeof onAegisInitAndPluginLoaded !== 'function'
    ) throw new TypeError('onAegisInit and onAegisInitAndPluginLoaded must be function');

    this.use(new Plugin<Aegis>({
      name: 'asyncPlugin',
      onNewAegis(aegis) {
        try {
          onAegisInit(aegis);
          if (Aegis._asyncPlugin[url]) {
            onAegisInitAndPluginLoaded(
              aegis,
              // @ts-ignore
              window[Aegis._asyncPlugin[url]]
            );
          } else {
            loadScript(url, exportsConstructor, (err: boolean) => {
              if (err) return;
              Aegis._asyncPlugin[url] = exportsConstructor;
              // @ts-ignore
              const ExportedConstrocutor = window[exportsConstructor];
              onAegisInitAndPluginLoaded(aegis as Aegis, ExportedConstrocutor);
            });
          }
        } catch (e) {
          console.log(`error on below is caused by ${url}：`);
          console.error(e);
        }
      },
    }));
  }
  flog: any;

  // 测速日志管道，之所以写在这里，是为了让所有测速日志都走同一个管道节流，尽可能的少发 http 请求
  _speedLogPipeline = createPipeline([
    // 抽样
    createSpeedRepeatLimitPipe(),
    // 节流
    createThrottlePipe(this.config),
    // 更新网络状态，支持异步接口
    createSpeedNetworkRefreshPipe(this),
    // 钩子beforeReportSpeed，ps: 只有 config 中的 beforeReportSpeed 能阻止上报
    (logs, resolve) => {
      this.lifeCycle.emit('beforeReportSpeed', logs);
      const { beforeReportSpeed } = this.config;
      if (typeof beforeReportSpeed === 'function') {
        logs = logs.filter((log: any) => {
          const needReport =  beforeReportSpeed(log) !== false;
          if (log.type === 'fetch' && log.ret === undefined && log.payload) {
            // 用户将资源类型扭转了，需要重新获取一遍ret
            log.ret = tryToGetRetCode(log.payload.data.response, this.config.api);
          }
          return needReport;
        });
      }
      if (logs.length) {
        return resolve(logs);
      }
    },
    (logs) => {
      this.send({
        url: `${this.config.speedUrl}`,
        method: 'post',
        data: speedShim(logs, this.bean),
      });
    },
  ]);

  constructor(config: WebConfig) {
    super(config);
    // 默认打开异步组件加载模式
    config.asyncPlugin = true;
    // 调用 Core 安装插件
    try {
      config.uin = config.uin
                    || parseInt((document.cookie.match(/\buin=\D+(\d*)/) || [])[1], 10)
                    || parseInt((document.cookie.match(/\bilive_uin=\D*(\d+)/) || [])[1], 10)
                    || '';

      this.init(config);
      this.extendBean('sessionId', Aegis._sessionID);
      this.extendBean('from', encodeURIComponent(config.pageUrl || location.href));
      this.extendBean('referer', document.referrer);
    } catch (e) {
      console.warn(e);
      console.log(
        '%c以上错误发生在初始化 Aegis 的过程中，将会影响您正常使用 Aegis，\n建议您联系 aegis-helper，进行反馈，感谢您的支持。',
        'color: red'
      );
      this._sendSDKError(e);
    }
  }

  _bean(filter: string[] = []) {
    return (
      `${Object.getOwnPropertyNames(this.bean)
        .filter(key => filter.indexOf(key) === -1)
        .map(key => `${key}=${this.bean[key]}`)
        .join('&')}`
    );
  }

  send = (options: SendOption, success?: SendSuccess, fail?: SendFail) => {
    if (!options || typeof options.url !== 'string' || options.url === '' || !this.bean.id) {
      // 参数错误或者没有项目ID，不让发请求
      return;
    }

    // 当 options.addBean !== false 时默认带上 bean 中的参数.
    let { url } = options;
    if (options.addBean !== false) {
      url = `${url}${url.indexOf('?') === -1 ? '?' : '&'}${this._bean(options.beanFilter)}`;
    }
    const method = options.method || 'get';

    const xhr = new XMLHttpRequest();
    // aegis的所有上报都使用的 “send” 方法，在这里加上标志，防止 aegis 监听到自己的请求造成递归
    xhr.__sendByAegis = true;
    xhr.addEventListener('readystatechange', () => {
      if (xhr.readyState === 4) {
        if (xhr.status >= 400) {
          fail?.(xhr.response);
        } else {
          success?.(xhr.response);
        }
      }
    });
    if (method.toLocaleLowerCase() === 'get') {
      xhr.open('get', buildParam(url, options.data));
      xhr.send();
    } else {
      xhr.open('post', url);
      if (options.contentType) {
        xhr.setRequestHeader('Content-Type', options.contentType);
      }
      if (typeof options.data === 'string') {
        options.data = options.data.replace(/eval/gi, 'evaI');
      }
      xhr.send(options.data);
    }
  };

  uploadLogs(params: any = {}, conds: any = {}) {
    this.lifeCycle.emit('uploadLogs', params, conds);
  }

  static get urls() {
    return {
      aegisCollect: 'https://aegis.qq.com/collect',
      flog: 'https://cdn-go.cn/vasdev/web_webpersistance_v2/latest/flog.core.min.js',
      shadowLog: '',
    };
  }
}
