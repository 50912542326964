import { Pipe } from './index';
import Core, { LOG_TYPE, NormalLog } from '@tencent/aegis-core';

export function createWhitelistPipe(aegis: Core): Pipe {
  let isWhiteList = false;
  let requestEnd = false;

  // 请求白名单接口
  // 这个 “setTimeout” 存在是因为 “createWhitelistPipe” 被执行时子类的 “send” 方法还没覆盖 “Core” 的 “send” 占位
  // 晚点执行请求也有好处，防止业务方说 Aegis 请求阻塞他们的重要请求
  // 接收到config变化后再执行，初始化会触发一次，每次setConfig也会触发
  aegis.lifeCycle.on('onConfigChange', () => {
    setTimeout(() => {
      aegis.send(
        {
          url: aegis.config.whiteListUrl || '',
        },
        (res) => {
          requestEnd = true;
          try {
            res = JSON.parse(res) || {};
            if (res.retcode === 0) {
              isWhiteList = res.result.is_in_white_list;
            }
          } catch (e) {
          }
        },
        () => {
          requestEnd = true;
        }
      );
    }, 50);
  });

  // 不确定是否是白名单时暂存的数据
  // TODO 这里有一个坑，存在 pool 里的数据，如果后续没有日志上报，pool 将不会被报上去。。
  const pool: NormalLog[] = [];
  return function (logs: NormalLog[], resolve) {
    if (isWhiteList) {
      // 白名单，清空并带上pool中的日志
      // 修正INFO_ALL类型为INFO类型（后端并不能识别INFO_ALL类型）
      resolve(logs.concat(pool.splice(0)).map((log) => {
        log.level === LOG_TYPE.INFO_ALL && (log.level = LOG_TYPE.INFO);
        return log;
      }));
    } else {
      // 非白名单或者白名单请求还未结束
      const otherLog = logs.filter((log) => {
        if (
          log.level !== LOG_TYPE.INFO && log.level !== LOG_TYPE.API_RESPONSE
        ) {
          // 修正INFO_ALL类型为INFO类型（后端并不能识别INFO_ALL类型）
          log.level === LOG_TYPE.INFO_ALL && (log.level = LOG_TYPE.INFO);
          // 除了INFO类型和API_RESPONSE类型需要判断白名单外，其他日志类型都直接上报
          return true;
        }

        if (!requestEnd) {
          // 白名单请求还没结束
          pool.push(log);
          pool.length >= 200 && (pool.length = 200);
        }
      });

      // 必须判断长度，不然后面的 pipe 会空跑
      otherLog.length && resolve(otherLog);
    }
  };
}
