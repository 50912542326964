import { Pipe } from '../../../core/src/pipes';
import Core, { SpeedLog } from '@tencent/aegis-core';
import { getNetworkType, NetworkTypeNum } from '../plugins/device';

// 上报前更新网络状态
export function createSpeedNetworkRefreshPipe(aegis: Core): Pipe {
  return (log: SpeedLog | SpeedLog[], resolve) => {
    getNetworkType((networkType: NetworkTypeNum) => {
      aegis.extendBean('netType', networkType);
      resolve(log);
    });
  };
}
