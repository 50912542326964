interface Handler {
    name: string;
    type: number;
    callback: Function;
}

export interface InterfaceEventEmiter {
    indexOf: Function;
    on: Function;
    one: Function;
    emit: Function;
    remove: Function;
}

export class EventEmiter {
    __EventsList!: {
        [propName: string]: Handler[];
    };
    constructor() {
      this.__EventsList = {};
    }

    indexOf(array: any[], value: any) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].callback === value) {
          return i;
        }
      }
      return -1;
    }

    on(name: string, callback: Function, type = 0) {
      if (!this) return;

      let events = this.__EventsList[name];

      if (!events) {
        this.__EventsList[name] = [];
        events = this.__EventsList[name];
      }

      if (this.indexOf(events, callback) === -1) {
        const handler = {
          name,
          type: type || 0,
          callback,
        };

        events.push(handler);

        return this;
      }

      return this;
    }

    one(name: string, callback: Function) {
      this.on(name, callback, 1);
    }

    emit = (name: string, data: any) => {
      if (!this) return;

      let events = this.__EventsList[name];
      let handler!: Handler;
      if (events?.length) {
        events = events.slice();
        // const self = this;

        for (let i = 0; i < events.length; i++) {
          handler = events[i];
          try {
            const result = handler.callback.apply(this, [data]);
            if (1 === handler.type) {
              this.remove(name, handler.callback);
            }
            if (false === result) {
              break;
            }
          } catch (e) {
            throw e;
          }
        }
      }
      return this;
    };

    remove(name: string, callback: Function) {
      if (!this) return;

      const events = this.__EventsList[name];

      if (!events) {
        return null;
      }

      if (!callback) {
        try {
          delete this.__EventsList[name];
        } catch (e) {}
        return null;
      }

      if (events.length) {
        const index = this.indexOf(events, callback);
        events.splice(index, 1);
      }

      return this;
    }
}
