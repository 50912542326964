import { Plugin } from '@tencent/aegis-core';

let plugin = new Plugin({ name: 'tjg' });

if (TJG) {
  let alreadySetTjgHeader = false;
  // 接入天机阁全链路日志
  plugin = new Plugin({
    name: 'tjg',
    onNewAegis(aegis) {
      this.setTjgHeader(aegis);
    },
    setTjgHeader(aegis: any) {
      // 只重写一次
      if (alreadySetTjgHeader) return;
      alreadySetTjgHeader = true;
      this.overrideFetch(aegis);
      this.overrideXHR(aegis);
    },
    overrideFetch(aegis: any) {
      if (typeof window.fetch !== 'function') return;
      const self = this;
      const originFetch = window.fetch;
      window.fetch = function aegisTjgFakeFetch(url: string, option) {
        // 同源才注入天机阁头部
        if (self.isSameOrigin(url)) {
          if (typeof option === 'object' && option) {
            if (option.headers) {
              if (option.headers instanceof Headers) {
                option.headers.append(
                  'X-Tjg-Json-Span-Context',
                  self.getTjgHeaderValue(aegis)
                );
              }
            } else {
              option.headers = new Headers({
                'X-Tjg-Json-Span-Context': self.getTjgHeaderValue(aegis),
              });
            }
          } else {
            option = {
              headers: new Headers({
                'X-Tjg-Json-Span-Context': self.getTjgHeaderValue(aegis),
              }),
            };
          }
        }

        return originFetch(url, option);
      };
    },
    overrideXHR(aegis: any) {
      if (!window.XMLHttpRequest) return;
      const self = this;
      const originSend = window.XMLHttpRequest.prototype.send;
      const originOpen = window.XMLHttpRequest.prototype.open;

      window.XMLHttpRequest.prototype.open = function aegisTjgFakeOpen() {
        // aegis 发的请求不做修改
        if (!this.__sendByAegis && self.isSameOrigin(arguments[1])) {
          this.useTjg = true;
        }
        return originOpen.apply(this, arguments);
      };

      window.XMLHttpRequest.prototype.send = function aegisTjgFakeSend() {
        if (this.useTjg) {
          this.setRequestHeader(
            'X-Tjg-Json-Span-Context',
            self.getTjgHeaderValue(aegis)
          );
        }
        return originSend.apply(this, arguments);
      };
    },
    getTjgHeaderValue(aegis: any) {
      return  window.btoa && window.btoa(JSON.stringify({
        ids: {
          trace_id: {
            high: this.rand53(),
            low: this.rand53(),
          },
          span_id: this.rand53(),
          parent_id: 0,
          flag: 2, // 0代表采样不命中，2代表采样命中
        },
        baggages: {
          aegis_session_id: aegis._sessionID,
        },
      }));
    },
    rand53() {
      return parseInt(
        new Array(53)
          .fill(1)
          .map(() => (Math.random() > 0.5 ? 1 : 0))
          .join(''),
        2
      );
    },
    isSameOrigin(url: string): boolean {
      const a = document.createElement('a');
      a.href = url;
      return location.origin === a.origin;
    },
  });
}

export default plugin;
