import { SpeedLog } from '@tencent/aegis-core';

/**
 * 过滤log
 * @param {Any} log 要发送单个log对象
 * @return {Any} 过滤后的log对象
 */
function filteLog(log: any): any {
  if (log.payload) {
    const filterLog: any = {};
    // 使用复制的形式而使用delete的原因是为了用户在控制台打印log时可以看到此属性
    Object.keys(log).forEach((key: string) => {
      if (key !== 'payload') {
        filterLog[key] = log[key];
      }
    });
    return filterLog;
  }
  return log;
}

export function speedShim(logs: SpeedLog | SpeedLog[], bean: any) {
  const result = {
    fetch: [] as SpeedLog[],
    static: [] as SpeedLog[],
  };
  const formData = new FormData();

  if (Array.isArray(logs)) {
    logs.forEach((log) => {
      const filterLog = filteLog(log);
      result[log.type].push(filterLog);
    });
  } else {
    const filterLog = filteLog(logs);
    result[logs.type].push(filterLog);
  }

  // 拼接bean;
  formData.append(
    'payload',
    JSON.stringify({
      duration: result,
      ...bean,
    })
  );
  return formData;
}

export function loadScript(url: string, name?: string, cb?: Function) {
  const s: any = document.createElement('script');
  const { head } = document;
  if (typeof name === 'function') {
    cb = name;
    name = '';
  }
  s.src = url;
  s.setAttribute('name', name);
  s.name = name;
  s.async = true;
  s.onreadystatechange = function () {
    if (
      !s.readyState
            || s.readyState === 'loaded'
            || s.readyState === 'complete'
    ) {
      typeof cb === 'function' && cb(false);
      setTimeout(() => {
        head.contains(s) && head.removeChild(s);
      });
    }
  };
  s.onload = s.onreadystatechange;

  s.onerror = function () {
    // 执行插件
    typeof cb === 'function' && cb(true);
    setTimeout(() => {
      head.contains(s) && head.removeChild(s);
    });
  };
  head.appendChild(s);
}
