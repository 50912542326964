/**
 * @插件 该插件会监听web页面的错误，并发布至每一个 Aegis 实例
 */
import Core, { Plugin, LOG_TYPE, NormalLog, stringifyPlus } from '@tencent/aegis-core';

let plugin = new Plugin({ name: 'onError' });

if (ON_ERROR) {
  plugin = new Plugin({
    name: 'onError',
    init() {
      this.startListen();
    },
    // 监听错误
    startListen() {
      // 监听js执行错误
      const orgError = window.onerror;
      window.onerror = (...args) => {
        // 给每一个实例发送js错误
        this.publishErrorLog({
          msg: `${args[0] || ''} @ (${args[1] || ''}:${args[2] || 0}:${args[3] || 0})`,
          level: LOG_TYPE.ERROR,
        });

        orgError?.call(window, ...args);
      };

      // 监听未被catch的promise错误
      window.addEventListener('unhandledrejection', (event: any) => {
        const reason = event && stringifyPlus(event.reason);
        // 给每一个实例发送 Promise 未被 catch 错误日志
        this.publishErrorLog({
          msg: `PROMISE_ERROR: ${reason}`,
          level: LOG_TYPE.PROMISE_ERROR,
        });
      });

      // 监听静态资源加载错误
      window.document.addEventListener(
        'error',
        (event) => {
          if (!event || !event.target || !event.srcElement) return;

          const target = event.target || event.srcElement;
          const url = target.src || target.href;

          const { tagName } = target;

          // 将错误上报到错误日志
          if (
            url
            && url.indexOf('aegis.qq.com') === -1
            && tagName
          ) {
            const log: NormalLog = {
              msg: `${tagName} load fail: ${url}`,
              level: LOG_TYPE.INFO,
            };
            switch (tagName.toLowerCase()) {
            case 'script':
              log.level = LOG_TYPE.SCRIPT_ERROR;
              break;
            case 'link':
              log.level = LOG_TYPE.CSS_ERROR;
              break;
              // case 'img':
              //     log.level = LOG_TYPE.IMAGE_ERROR;
              //     break;
            case 'audio':
            case 'video':
              log.level = LOG_TYPE.MEDIA_ERROR;
              break;
            default:
              return;
            }
            this.publishErrorLog(log);
          }
        },
        true
      );

      // 监听ajax错误
      const xhrProto = window.XMLHttpRequest.prototype;
      const originOpen = xhrProto.open;
      const originSend = xhrProto.send;
      const self = this;
      xhrProto.open = function aegisFakeXhrOpen() {
        if (!this.aegisMethod || !this.aegisUrl) {
          this.aegisMethod = arguments[0];
          this.aegisUrl = arguments[1];
        }

        return originOpen.apply(this, arguments);
      };
      xhrProto.send = function aegisFakeXhrSend() {
        !this.__sendByAegis
        && this.addEventListener('loadend', function aegisXhrLoadendHandler() {
          if (this.aegisTimeout) {
            self.publishErrorLog({
              msg: `AJAX_ERROR: request timeout. status: ${
                this.status
              }. \n url: ${this.aegisUrl}`,
              level: LOG_TYPE.AJAX_ERROR,
            });
          } else if (this.status === 0) {
            self.publishErrorLog({
              msg: `AJAX_ERROR: request failed. status: ${
                this.status
              }. \n url: ${this.aegisUrl}`,
              level: LOG_TYPE.AJAX_ERROR,
            });
          } else if (this.status >= 400) {
            self.publishErrorLog({
              msg: `AJAX_ERROR: request error. status: ${
                this.status
              }. \n url: ${this.aegisUrl}`,
              level: LOG_TYPE.AJAX_ERROR,
            });
          }
        });
        !this.__sendByAegis
        && this.addEventListener('timeout', function aegisXhrLoadendHandler() {
          this.aegisTimeout = true;
        });

        return originSend.apply(this, arguments);
      };
    },
    // 分发错误日志
    publishErrorLog(msg: NormalLog | NormalLog[]) {
      this.$walk((aegis: Core) => {
        aegis._normalLogPipeline(msg);
      });
    },
  });
}

export default plugin;
